const likeButton = () => {

	const like = document.querySelectorAll(".like-link");
	like.forEach((item) => {
	  item.addEventListener('click', (e) => {
	    e.target.style.color = "blue"
	  });
	})
};

export { likeButton };