const dashboardOptions = () => {
	const form1 = document.querySelector('.influencer-form-1');

	if(form1){
		// if( windowWidth < 768 ){}
		[1,2].forEach((item) => {
			const btn = document.querySelector(`.inf-btn-${item}`);
			const form = document.querySelector(`.influencer-form-${item}`);
			const forms = document.querySelectorAll(`.influencer-update-form`);
			
			btn.addEventListener('click', (e) => {
			  
				forms.forEach((form_item) => {
			 		form_item.style.display = "none";
				});

				form.style.display = "block";
			});
		})
	};
};

export { dashboardOptions };