const mapButtons = () => {
	var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const switchButtons = document.querySelectorAll(".map-switch"); // this code is for the retreats page
	const left = document.querySelector(".discover-banner-left");
	const canvas = document.querySelector(".mapboxgl-canvas");
	const canvas_cont = document.querySelector(".mapboxgl-canvas-container");


	const mapElement = document.getElementById('map');

	// if(switchButtons.first) {
		// if( windowWidth < 768 ){
		//   mapElement.style.display = 'block';
		//   mapElement.style.display = 'none';
		// } else {
		//   mapElement.style.display = 'block';
		// }

		// switchButtons.forEach((button) => {
		//     button.addEventListener("click",(e) => {
		//         if (mapElement.style.display == 'block') {
		//           mapElement.style.display = 'none';
		//           left.style.width = '100%';
		//           left.style.height = '100%';
		//           button.innerText = 'OFF';
		//         } else {
		//           mapElement.style.display = 'block';
		//           button.innerText = 'ON';
		//           if( windowWidth < 768 ){
		//             // left.style.width = '100%';
		//             // left.style.height = '100%';
		//          	// canvas.style.top = '0';
		//          	// canvas.style.bottom = '0';
		//          	// canvas_cont.style.width =  '100%';
		//          	// canvas_cont.style.height =  '100%';
		//             // mapElement.style.position = 'absolute !important';
		//             // mapElement.style.bottom = '0';
		//             // mapElement.style.top = '0';
		//             // mapElement.style.right = '0';
		//             // mapElement.style.left = '0';

		//             // mapElement.style.height = '100%';
		//             // map.resize();
		//           } else {
		//             left.style.width = '50%';
		//             // map.resize();
		//           }

		//         };
		//     });
		// });

	// 	position: absolute;
 //  	top: 0;
 //  	right: 0;
 //  	left: 0;
 //  	bottom: 0;
 //  	// height: 100%;
	// // width: 100%;
	// .mapboxgl-canvas {
	// 	height: 100%;
	// 	width: 100%;
	// }

		// map.on('load', function () {
		//   map.resize();
		//   fitMapToMarkers(map, markers);
		// });
	// };
};

export { mapButtons };
