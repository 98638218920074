import { Controller } from "stimulus"
import Swal from 'sweetalert2'


export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude","city","place_id"]

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }

  coordinates(event) {
    //console.log(event)
    let city = this.cityTarget.value
    const locations = JSON.parse(this.mapTarget.dataset.location);
    locations.forEach((location) => {
      //console.log(location);
      //console.log(city);
      if (Object.keys(location)[0] == city) {
        //console.log("its a match");
        let latlng = location[city][0]
      }
    });
  }

  initMap() {
    let latlng = {
      lat: 38.736946,
      lng: -9.142685
    };

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get("latitude") || latlng.lat, this.data.get("longitude") || latlng.lng),
      zoom: (this.data.get("latitude") == null ? 2 : 10)
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name', 'place_id'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    console.log(place)

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
    this.place_idTarget.value = place.place_id
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
