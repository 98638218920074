// const infUpdateForm = () => { 
// 	const btn = document.querySelector(".toggle-container .inf-update-btn");
// 	const form = document.querySelector(".toggle-container .influencer-update-form");
// 	if(btn){
// 	  form.style.display = 'none';	
// 	  btn.addEventListener('click', (e) => {
// 	    if(form.style.display == 'none'){	
// 	    	form.style.display = 'block';
// 	    	// btn.style.display = 'none';
// 	    } else {
// 	    	form.style.display = 'none';
// 	    	// btn.style.display = 'block';	
// 	    }
// 	  });
// 	}  
// };

const infUpdateForm = () => { 
	const containers = document.querySelectorAll(".toggle-container")
	containers.forEach((container) => {
		// const btn = document.querySelector("." + container + "." + btn);
		// const form = document.querySelector("." + form);
		if(container.childNodes[1]){
		  // console.log(container.childNodes[1])
		  container.childNodes[3].style.display = 'none';	
		  container.childNodes[1].addEventListener('click', (e) => {
		    if(container.childNodes[3].style.display == 'none'){	
		    	container.childNodes[3].style.display = 'block';
		    	// btn.style.display = 'none';
		    } else {
		    	container.childNodes[3].style.display = 'none';
		    	// btn.style.display = 'block';	
		    }
		  });
		}  
	})
};

export { infUpdateForm };