const navbarDropdown = () => { 
	const btn = document.querySelector(".drop-arrow");
	const container = document.querySelector(".nav-content-mobile");
	const parent = document.querySelector(".custom-navbar-container");

	if(btn)
	{
		btn.addEventListener('click', (e) => {
			if (btn) {
				if (container.style.display == 'block') {
					container.style.display = 'none';
					btn.style.transform = 'rotate(0deg)';
				} else {
					container.style.display = 'block';
					btn.style.transform = 'rotate(-180deg)';								
					parent.style.top = 'none';
					parent.style.bottom = '0px';
				}
			}	  
		});
	}
};

export { navbarDropdown };