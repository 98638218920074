const communityFilterButton = () => { 
	// const btn = document.querySelector(".community-btn-container");
	const btn = document.querySelector(".community-filter-btn");
	const form = document.querySelector(".community-filter-container");
	if(btn){
	  form.style.display = 'none';	
	  btn.addEventListener('click', (e) => {
	    if(form.style.display == 'none'){	
	    	form.style.display = 'inline-block';
	    	btn.innerText = 'Filters -';
	    } else {
	    	form.style.display = 'none';
	    	btn.innerText = 'Filters +';	
	    }
	  });
	}  
};

export { communityFilterButton };