import { Controller } from "stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  connect() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      confirmButtonText: 'Go Back Home',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function () {
      window.location = "https://www.daytrippers.travel"
    })
  }
}
