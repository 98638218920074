import $ from 'jquery';
import 'select2';
import "controllers"


// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note: ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import "trix"
import "@rails/actiontext"

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

// if(document.querySelector("explore-banner").length) {
import { carouselMotion } from '../components/carousel';
// };

import { carouselMotionMain } from '../components/carousel_main';
import { dropdownFormSubmit } from '../components/dropdown_form';
import { avatarCarouselMotion } from '../components/avatar_carousel';
import { showCarousel } from '../components/show_carousel';
import { filterSwitch } from '../components/filter_switch';
import { communityFilterButton } from '../components/community_filter_btn';
import { infUpdateForm } from '../components/influencer_update_btn';
import { Message404, Message500 } from '../components/error_messages';


import { previewImageOnFileSelect } from '../components/photo_preview';
import { tribeCarousel } from '../components/tribe_carousel';
import { likeButton } from '../components/like_button';
import { categoriesCarousel } from '../components/categories_carousel';
import { campaignMotionMain } from '../components/campaign_carousel';
import { sustainableMotionMain } from '../components/sustainable_carousel';
import { navbarDropdown } from '../components/navbar';
import { dashboardOptions } from '../components/dashboard_options';
import { mapButtons } from '../components/map_switch';
import { RemoveAttach } from '../components/remove_attach';
import { initChatroomCable } from '../channels/chatroom_channel';

import { downloadButton } from '../components/download_button';







document.addEventListener('turbolinks:load', () => {

  window.initMap = function (...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
  }

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()


$('.select-selector').select2({
  width: 300
});

// Bug fix for double select 2

if (document.querySelector(".pages-explore")) {
  if (document.querySelectorAll(".select2").length > 1)
   {
     const nodelist = document.querySelectorAll(".select2")
     let counter = document.querySelectorAll(".select2").length - 1
     console.log(counter)
     while (counter > 0) {
       console.log(counter)
      nodelist[counter].remove()
      counter = counter - 1
     }
   }
}

  if (document.querySelector(".trix-button-group--file-tools")) {
    RemoveAttach();
  }




  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
      .then(function(reg) {
        console.log('[Companion]', 'Service worker registered!');
        console.log(reg);
      });
  }

if (document.querySelector(".errors-not_found")){
  Message404();
}

if (document.querySelector(".errors-internal_server_error")){
  Message500();
}

if (document.querySelector(".map-off")) {
  if (window.getComputedStyle(document.querySelector(".map-off")).display === "none") {
    const myElement = document.querySelector(".dropdown-form-discover")
    console.log(myElement)
    myElement.classList.add("arrow-discovery-map")
  }
}

if (document.querySelector(".map-on")) {
  if (window.getComputedStyle(document.querySelector(".map-on")).display === "none") {
  const myElement = document.querySelector(".dropdown-form-discover")
   myElement.classList.remove("arrow-discovery-map")
  }
}

// $(document).ready(function() {
//     $('.select-selector').select2();
// });

// $('.select-selector').select2();
//   (function($){
//      "use strict";
//       $(document).on('ready', function(){
//           $(".select-selector").select2({
//               allowClear: true,
//               theme: "bootstrap"
//           });
//       });
//   }(jQuery));

  // $(document).ready(function(){
    // Turn on js-selectable class so that it becomes SELCT 2 tag

  // });


  // Call your functions here, e.g:
  // initSelect2();
   // if ('serviceWorker' in navigator) {
   //    navigator.serviceWorker.register('/serviceworker.js');
   //  }
	  // var element = document.querySelector('.explore-container');
	  // var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
	  // var observer = new MutationObserver(carouselMotion("trendi", "cont", "right", "left"))
	  // observer.observe(element, {
	  //   childList: true
	  // });
    initChatroomCable();
  downloadButton();
  showCarousel();
  filterSwitch();
  infUpdateForm();
  communityFilterButton();
  previewImageOnFileSelect();
  likeButton();
  navbarDropdown();
  dashboardOptions();
  mapButtons();
  // CAROUSELLS
  carouselMotion("near", "cont-near", "right-near", "left-near"); //landing page near user activities
  carouselMotion("trendig", "cont", "right", "left");
  carouselMotion("featured", "cont1", "right1", "left1");
  carouselMotion("culture", "cont2", "right2", "left2");
  carouselMotion("outdoors", "cont-outdoors", "right-outdoors", "left-outdoors");
  tribeCarousel("tribe-footer-marker", "tribe-footer-container", "tribe-footer-right", "tribe-footer-left");
 //               marker             container              right arrow           right arrow
  tribeCarousel("tribe", "cont4", "right4", "left4");
  tribeCarousel("tribe-landing", "cont-landing", "right-landing", "left-landing");
  categoriesCarousel("categories", "categories-cont", "categories-right", "categories-left");
  categoriesCarousel("categories", "cont5", "right5", "left5");
  campaignMotionMain();
  sustainableMotionMain();
  carouselMotionMain('carousel-main-left', 'carousel-main-right', 'carousel-main-container', 'carousel-main-card');
  carouselMotionMain('carousel-main-left', 'carousel-main-right', 'carousel-main-container', 'carousel-community-card');
});




require("trix")
require("@rails/actiontext")