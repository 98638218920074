const campaignMotionMain = (left, right, container, card) => {
	if(document.querySelector(".campaign-main-card")) {
		var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const leftArrow = document.querySelector(".campaign-main-left");
		const rightArrow = document.querySelector(".campaign-main-right");
		const carouselContainer = document.querySelector(".campaign-main-container");
		const carouselCardNum = document.querySelectorAll(".campaign-main-card");
		let counterRight = 0;
		let counterLeft = 0;

		if(carouselContainer) {
			carouselContainer.addEventListener('touchstart', handleTouchStart, false);        
			carouselContainer.addEventListener('touchmove', handleTouchMove, false);		
		}
		var xDown = null;                                                        
		var yDown = null;  


		if(rightArrow) {
			rightArrow.addEventListener("click", (e) => {
				console.log(width);
				console.log(carouselCardNum.length)
				console.log(counterRight)
				if(width > 1024) {	  
					if(counterRight < (carouselCardNum.length - 3)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 27 + 'vw';
					}
				} else {
					if(counterRight < (carouselCardNum.length/2)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 48 + 'vw';
					}
				}
			});

			leftArrow.addEventListener('click', (e) => {
				if(width > 1024) {	  
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 27 + 'vw';
					}
				} else {
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 48 + 'vw';
					}
				}
			});
		}

		function handleTouchStart(evt) {                                         
		    xDown = evt.touches[0].clientX;                                      
		    yDown = evt.touches[0].clientY;                                      
		}; 

		function handleTouchMove(evt) {
		    if ( ! xDown || ! yDown ) {
		        return;
		    }
		    var xUp = evt.touches[0].clientX;                                    
		    var yUp = evt.touches[0].clientY;
		    var xDiff = xDown - xUp;
		    var yDiff = yDown - yUp;

		    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
		        if ( xDiff > 0 ) {
		        /* left swipe */ 
		   			if(width > 1224) {	  
						if(counterRight < (carouselCardNum.length - 3)) {
							counterLeft -= 1;
							counterRight += 1;
							carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 34 + 'vw';
						}
					} else {
						if(counterRight < (carouselCardNum.length)) {
							counterLeft -= 1;
							counterRight += 1;
							carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 92 + 'vw';
						}
					}
		        } else {
		        /* right swipe */
		   			if(width > 1224) {	  
						if(counterLeft < 0) {
							counterLeft += 1;
							counterRight -= 1;
							carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 34 + 'vw';
						}
					} else {
						if(counterLeft < 0) {
							counterLeft += 1;
							counterRight -= 1;
							carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 92 + 'vw';
						}
					}

		        }                       
		    } else {
		        if ( yDiff > 0 ) {
		        /* up swipe */ 
		        } else { 
		        /* down swipe */
		        }                                                                 
		    }
		    /* reset values */
		    xDown = null;
		    yDown = null;                                             
		};
	}
}

export { campaignMotionMain };