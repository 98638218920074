const previewImageOnFileSelect = () => {
  // we select the photo input
  if(document.getElementById('photo-preview') && document.getElementById('photo-input')){
    const input = document.getElementById('photo-input');
    document.getElementById('photo-preview').style.display = 'none';
    if (input) {
      // we add a listener to know when a new picture is uploaded
      input.addEventListener('change', () => {
        // we call the displayPreview function (who retrieve the image url and display it)
        displayPreview(input);
      })
    }
  }
}

const displayPreview = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById('photo-preview').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    // document.getElementById('photo-preview').classList.remove('hidden');
    document.getElementById('photo-preview').style.display = 'block';
    document.querySelector('.upload-photo').style.display = 'none';
    document.querySelector('#photo-preview').style.height = '312px';
    document.querySelector('#photo-preview').style.width = '306px';
    // 'url(' + event.currentTarget.result + ')';
  }
}

export { previewImageOnFileSelect };