import Typed from 'typed.js';



const Message404 = () => {
    const typed = new Typed('.text', {
        strings: [
          "Oops! Looks like this page does not exist!. <br /> ^1000" + 
          "Sorry about that. <br /> ^1000" +
          "Let me try and help. <br /> ^1000" +
          "Go back <a href='/'>home</a> and start over."
        ],
        typeSpeed: 0,
        showCursor: false
      });

  };

  const Message500 = () => {
    const typed = new Typed('.text', {
        strings: [
          "Oops! 500 Error! <br /> ^1000" + 
          "Sorry about that. <br /> ^1000" +
          "Something is in the works. <br /> ^1000" +
          "Go back <a href='/'>home</a> and start over."
        ],
        typeSpeed: 0,
        showCursor: false
      });
  };

export { Message404 , Message500}