const showCarousel = () => {
	console.log("hello")
	var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const leftArrow = document.querySelector(".carousel-show-left");
	const rightArrow = document.querySelector(".carousel-show-right");
	const carouselContainer = document.querySelector(".show-carousel-container");
	const carouselCardNum = document.querySelectorAll(".show-image");
	let counterRight = 0;
	let counterLeft = 0;

	if(carouselContainer) {
		carouselContainer.addEventListener('touchstart', handleTouchStart, false);        
		carouselContainer.addEventListener('touchmove', handleTouchMove, false);		
	}
	var xDown = null;                                                        
	var yDown = null;

	if(rightArrow) {
	rightArrow.addEventListener("click", (e) => {
		if(width > 1224) {	  
			if(counterRight < (carouselCardNum.length - 3)) {
				counterLeft -= 1;
				counterRight += 1;
				carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 33.33 + 'vw';
			}
		} else {
			if(counterRight < (carouselCardNum.length - 1)) {
				counterLeft -= 1;
				counterRight += 1;
				carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 100 + 'vw';
			}
		}
	});

	leftArrow.addEventListener('click', (e) => {
		if(width > 1224) {	  
			if(counterLeft < 0) {
				counterLeft += 1;
				counterRight -= 1;
				carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 33.33 + 'vw';
			}
		} else {
			if(counterLeft < 0) {
				counterLeft += 1;
				counterRight -= 1;
				carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 100 + 'vw';
			}
		}
	});
	}

	function handleTouchStart(evt) {                                         
	    xDown = evt.touches[0].clientX;                                      
	    yDown = evt.touches[0].clientY;                                      
	}; 

	function handleTouchMove(evt) {
	    if ( ! xDown || ! yDown ) {
	        return;
	    }
	    var xUp = evt.touches[0].clientX;                                    
	    var yUp = evt.touches[0].clientY;
	    var xDiff = xDown - xUp;
	    var yDiff = yDown - yUp;

	    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
	        if ( xDiff > 0 ) {
	        /* left swipe */ 
	   			if(width > 1224) {	  
					if(counterRight < (carouselCardNum.length - 3)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 33.33 + 'vw';
					}
				} else {
					if(counterRight < (carouselCardNum.length/2)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 100 + 'vw';
					}
				}
	        } else {
	        /* right swipe */
	   			if(width > 1224) {	  
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 33.33 + 'vw';
					}
				} else {
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 100 + 'vw';
					}
				}

	        }                       
	    } else {
	        if ( yDiff > 0 ) {
	        /* up swipe */ 
	        } else { 
	        /* down swipe */
	        }                                                                 
	    }
	    /* reset values */
	    xDown = null;
	    yDown = null;                                             
	};
}

export { showCarousel };