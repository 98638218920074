const filterSwitch = () => {
	var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const switchButton = document.querySelector(".switch"); // this code is for the retreats page 
	const sortContainer = document.querySelector(".search_and_sort"); // this code is for the retreats page 
	const filtersContainer = document.querySelector(".filters_container_parent"); // this code is for the retreats page 
	if(windowWidth < 1024 && filtersContainer && sortContainer && switchButton ){
		sortContainer.style.display = 'none';
		filtersContainer.style.display = 'none';
		switchButton.addEventListener('click', (e) => {
		 	if (sortContainer.style.display == 'block') {
				sortContainer.style.display = 'none';
			} else {
				sortContainer.style.display = 'block';
			}
			if (filtersContainer.style.display == 'block') {
				filtersContainer.style.display = 'none';
			} else {
				filtersContainer.style.display = 'block';
			} 
		});	
	}
}

export { filterSwitch };
