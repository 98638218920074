const tribeCarousel = (card, container, right, left) => {
	var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const leftArrow = document.querySelector(`.${left}	`);
	const rightArrow = document.querySelector(`.${right}`);
	const carouselContainer = document.querySelector(`.${container}`);
	const carouselCardNum = document.querySelectorAll(`.${card}`);
	let counterRight = 0;
	let counterLeft = 0;

	if(carouselContainer) {
		carouselContainer.addEventListener('touchstart', handleTouchStart, false);        
		carouselContainer.addEventListener('touchmove', handleTouchMove, false);		
	}
	var xDown = null;                                                        
	var yDown = null;  


	if(rightArrow) {
		rightArrow.addEventListener("click", (e) => {
			leftArrow.style.display = "block";
			
			if(width > 1224) {	  
				if(counterRight < (carouselCardNum.length - 2)) {
					counterLeft -= 1;
					counterRight += 1;
					carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 20 + 'vw';
				}
			} else {
				if(counterRight < (carouselCardNum.length - 1)) {
					counterLeft -= 1;
					counterRight += 1;
					carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 100 + 'vw';
				}
			}

			if(counterRight < carouselCardNum.length - 1) {
				rightArrow.style.display = "block";
			} else {
				rightArrow.style.display = "none";
			}
		});

		leftArrow.addEventListener('click', (e) => {
			rightArrow.style.display = "block";
			
			if(width > 1224) {	  
				if(counterLeft < 0) {
					counterLeft += 1;
					counterRight -= 1;
					carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 20 + 'vw';
				}
			} else {
				if(counterLeft < 0) {
					counterLeft += 1;
					counterRight -= 1;
					carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 100 + 'vw';
				}
			}

			if(counterLeft < 0) {
				leftArrow.style.display = "block";
			} else {
				leftArrow.style.display = "none";
			}
		});
	}

	function handleTouchStart(evt) {                                         
    	xDown = evt.touches[0].clientX;                                      
    	yDown = evt.touches[0].clientY;                                      
	}; 

	function handleTouchMove(evt) {
	    if ( ! xDown || ! yDown ) {
	        return;
	    }
	    var xUp = evt.touches[0].clientX;                                    
	    var yUp = evt.touches[0].clientY;
	    var xDiff = xDown - xUp;
	    var yDiff = yDown - yUp;

	    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
	        if ( xDiff > 0 ) {
	        /* right swipe */
	        	leftArrow.style.display = "block"; 
	   			if(width > 1224) {	  
					if(counterRight < (carouselCardNum.length - 2)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 20 + 'vw';
					}
				} else {
					if(counterRight < (carouselCardNum.length - 1)) {
						counterLeft -= 1;
						counterRight += 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) - 100 + 'vw';
					}
				}

				if(counterRight < carouselCardNum.length - 1) {
					rightArrow.style.display = "block";
				} else {
					rightArrow.style.display = "none";
				}
	        } else {
	        /* left swipe */
	        	rightArrow.style.display = "block";
	   			if(width > 1224) {	  
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 20 + 'vw';
					}
				} else {
					if(counterLeft < 0) {
						counterLeft += 1;
						counterRight -= 1;
						carouselContainer.style.left = +carouselContainer.style.left.slice(0, -2) + 100 + 'vw';
					}
				}

				if(counterLeft < 0) {
					leftArrow.style.display = "block";
				} else {
					leftArrow.style.display = "none";
				}

	        }                       
	    } else {
	        if ( yDiff > 0 ) {
	        /* up swipe */ 
	        } else { 
	        /* down swipe */
	        }                                                                 
	    }
	    /* reset values */
	    xDown = null;
	    yDown = null;                                             
	};
}

export { tribeCarousel };